<template>
	<div class="tool-page">
		<Introduce
			:title="$t('tools.cp-widgets.title')"
			:titleBefore="$t('tools.cp-widgets.title-sm1')"
			:titleSm="$t('tools.cp-widgets.title-sm2')"
			:content="$t('tools.cp-widgets.content')"
			:listFeature="listFeature"
		>
			<template #button>
				<b-button variant="primary" class="custom-btn" @click="goToLogin()">
          <div class="text-white-button button-large text-nowrap d-flex justify-content-center">
            {{ $t("tools.economic-calendar.start-btn") }}
          </div>
				</b-button>
			</template>
			<template #itemRight>
				<div class="layout-right">
					<img src="@/assets/toolImg/widgets1.png" alt="" class="img1">
				</div>
			</template>
		</Introduce>
		<div class="title title-tool-page">
			{{ $t("tools.cp-widgets.begin1") }}
			<span class="text-primary">
				{{ $t("tools.cp-widgets.begin2") }}
			</span>
		</div>
		<!-- row 1 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.cp-widgets.content-item-title1") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.cp-widgets.content-item-text1") }}
					<span class="bold">
						{{ $t("tools.cp-widgets.content-item-text2") }}
					</span>
					{{ $t("tools.cp-widgets.content-item-text3") }}
					<span class="bold">
						{{ $t("tools.cp-widgets.content-item-text4") }}
					</span>
				 </div>
			</div>
			<img src="@/assets/toolImg/widgets2.png" alt="">
		</div>
		<!-- row 2 -->
		<div class="content-item">
			<img src="@/assets/toolImg/widgets3.png" alt="" class="hinde-md">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.cp-widgets.content-item-title2") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.cp-widgets.content-item-text5") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/widgets3.png" alt="" class="hinde-sm">
		</div>
		<!-- row 3 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.cp-widgets.content-item-title3") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.cp-widgets.content-item-text6") }}
					<span class="bold">
						{{ $t("tools.cp-widgets.content-item-text7") }}
					</span>
					{{ $t("tools.cp-widgets.content-item-text8") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.cp-widgets.content-item-text9") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/widgets4.png" alt="">
		</div>
		<!-- row 4 -->
		<div class="content-item-full">
			<div class="content-item-full__title">
				{{ $t("tools.cp-widgets.content-item-title4") }}
			</div>
			<img src="@/assets/toolImg/widgets5.png" alt="" class="hinde-md img-full"> 
			<div class="content-item-full__info">
				<div class="content-item-full__info__text">
					<div class="bold-all">
						{{ $t("tools.cp-widgets.content-item-text10") }}
					</div>
					{{ $t("tools.cp-widgets.content-item-text11") }}
				</div>
				<div class="content-item-full__info__text">
					<div class="bold-all">
						{{ $t("tools.cp-widgets.content-item-text12") }}
					</div>
					{{ $t("tools.cp-widgets.content-item-text13") }}
				</div>
			</div>
			<div class="content-item-full__info">
				<div class="content-item-full__info__text">
					<div class="bold-all">
						{{ $t("tools.cp-widgets.content-item-text14") }}
					</div>
					{{ $t("tools.cp-widgets.content-item-text15") }}
				</div>
				<div class="content-item-full__info__text">
					<div class="bold-all">
						{{ $t("tools.cp-widgets.content-item-text16") }}
					</div>
					{{ $t("tools.cp-widgets.content-item-text17") }}
				</div>
			</div>
			<img src="@/assets/toolImg/widgets5.png" alt="" class="hinde-sm"> 
		</div>
		<!-- row 5 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.cp-widgets.content-item-title5") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.cp-widgets.content-item-text18") }}
					<span class="bold">
						{{ $t("tools.cp-widgets.content-item-text19") }}
					</span>
					{{ $t("tools.cp-widgets.content-item-text20") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.cp-widgets.content-item-text21") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/widgets6.png" alt="">
		</div>
		<!-- row 6 -->
		<div class="content-item-full">
			<div class="content-item-full__info">
				<div>
					<img src="@/assets/toolImg/widgets7.png" alt="" class="hinde-md">
					<div class="content-item-full__title">
						{{ $t("tools.cp-widgets.content-item-title6") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.cp-widgets.content-item-text22") }}
					</div>
					<img src="@/assets/toolImg/widgets7.png" alt="" class="hinde-sm">
				</div>
				<div>
					<img src="@/assets/toolImg/widgets8.png" alt="" class="hinde-md">
					<div class="content-item-full__title">
						{{ $t("tools.cp-widgets.content-item-title7") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.cp-widgets.content-item-text23") }}
						<div class="hinde-sm enter-paragraphs"></div>
						{{ $t("tools.cp-widgets.content-item-text24") }}
					</div>
					<img src="@/assets/toolImg/widgets8.png" alt="" class="hinde-sm">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Introduce from "@/components/tool/Introduce.vue";
import { mapGetters } from 'vuex';
export default {
	components: {
		Introduce,
	},
	computed: {
    ...mapGetters({
      portal_url: 'portal_url'
    }),
		listFeature() {
			return [
				this.$t("tools.cp-widgets.feature1"),
				this.$t("tools.cp-widgets.feature2"),
				this.$t("tools.cp-widgets.feature3"),
				this.$t("tools.cp-widgets.feature4"),
				this.$t("tools.cp-widgets.feature5"),
				this.$t("tools.cp-widgets.feature6"),
			]
		}
  },
	data() {
		return {
			
		}
	},
	mounted() {

	},
	watch: {

	},
	methods: {
		goToLogin() {
      if (this.portal_url) window.open(`${this.portal_url}/register?lang=${this.$i18n.locale}`, '_blank');
    },
	},
	metaInfo: {
		title: "NOZAX: CP Widgets",
		meta: [
			{
				name: "description",
				content: "" ,
			},
		],
	},
};
</script>
<style lang="scss" scoped>
.title {
	max-width: 470px;
	@media (max-width: 880px) {
		margin-top: 130px;
		max-width: 320px;
  }
}
.content-item {
	margin-top: 96px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__info {
		width: 480px;
		&__title {
			font-size:  28px;
			font-weight: 500;
			font-family: Roboto;
			letter-spacing: -0.28px;
			width: 366px;
			color: #484C57;
		}
		&__note {
			font-size: 24px;
			font-weight: 400;
			font-family: Roboto;
			color: var(--primary);
			margin-top: 16px;
		}
		&__text {
			font-size: 18px;
			font-weight: 400; 
			margin-top: 16px;
			color: #616A70;
			margin-bottom: 32px;
		}
		img {
			width: 587px;
		}
	}
	@media (max-width: 1160px) {
    &__info {
			width: 396px;
			&__text {
				font-size: 16px;
			}
		}
		img {
			width: 420px;
		}
  }
	@media (max-width: 880px) {
		display: block;
		margin-top: 56px;
    &__info {
			width: 100%;
			&__title {
				font-size: 20px;
				letter-spacing: 0;
				width: 70%;
				min-width: 260px;
			}
			&__text {
				font-size: 18px;
			}
		}
		img {
			width: 100% ;
		}
  }
}
.content-item-full {
	margin-top: 120px;
	position: relative;
	&:last-child {
		margin-bottom: 110px;
	}
	&__title {
		font-size: 28px;
		font-weight: 500;
		font-family: Roboto;
		letter-spacing: -0.4px;
		color: #484C57;
	}
	&__info {
		display: flex;
		text-align: left;
		justify-content: space-between;
		margin-top: 32px;
		.content-item-full__title {
			margin-top: 48px;
			margin-bottom: 24px;
		}
		&__text {
 			font-size: 18px;
			font-weight: 400;
			max-width: 548px;
			color: #616A70;
		}
	}
	@media (max-width: 1160px) {
		&__info {
			&__text {
				width: 410px;
				font-size: 16px;
			}
			img {
				width: 410px;
			}
		}
		.img-full {
			width: 835px;
		}
  }
	@media (max-width: 880px) {
		margin-top: 56px;
		margin-bottom: 24px;
		&__title {
			font-size: 20px;
			text-align: left;
		}
		&__info {
			display: block;
			margin-top: 0;
			&__note {
				font-size: 20px;
				margin-top: 0;
			}
			&__text {
				margin-top: 16px;
				font-size: 18px;
				width: 100%;
			}
		}
		img {
			width: 100%;
			margin-top: 32px;
			margin-bottom: 32px;
		}
  }
}
.layout-right {
	margin-top: 160px;
	img {
		width: 100%;
	}
	@media (max-width: 1160px) {
		width: 445px;
    position: absolute;
		right: 0;
		margin-top: 240px;
  }
	@media (max-width: 880px) {
    position: relative;
		margin-top: 64px;
		width: 100%;
  }
}
</style> 