<template>
  <div class="intro">
    <div class="layout-left">
			<div class="head">
				{{ $t("tools.economic-calendar.get-start") }}
				<br>
				<span class="text-primary">{{ title }}</span>
			</div>
			<div class="head-sm">
				{{ titleBefore }}
				<span class="text-primary">{{ titleSm }}</span>
			</div>
			<slot name="content">
				<div class="content">
					{{ content }}
				</div>
			</slot>
			<template v-if="listFeature.length > 0">
				<div
					v-for="feature in listFeature"
					:key="feature"
					class="feature"
				>
					<IconList /> {{ feature }}
				</div>
			</template>
			<slot name="button" class="btn-slot"></slot>
    </div>
		<div>
			<slot name="itemRight"></slot>
		</div>
  </div>
</template>

<script>
import IconList from "@/svg/tool/IconList.vue";
export default {
	props: {
		titleBefore: {
			type: String,
		},
		title: {
			type: String,
			require: true
		},
		titleSm: {
			type: String,
			require: true
		},
		content: {
			type: String,
			require: true
		},
		listFeature: {
			type: Array,
			default: []
		},
	},
	components: {
		IconList
	},
  data() {
		return {
			
		}
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.intro {
	display: flex;
	justify-content: space-between;
	margin-top: 71px;
	position: relative ;
	font-family: Roboto;
	.layout-left {
		width: 580px;
		.head {
			font-size: 64px;
			font-style: normal;
			font-weight: 700;
			line-height: 65px;
			margin-bottom: 48px;
			letter-spacing: -0.64px;
			color: #39454C;
		}
		.head-sm {
			display: none;
			color: #39454C;
		}
		.content {
			font-size: 24px;
			font-weight: 400;
			margin-bottom: 24px;
			width: 100%;
			max-width: 565px;
			color: #616A70;
		}
		.feature {
			font-size: 24px;
			font-weight: 400;
			margin-bottom: 12px;
			color: #616A70;
		}
	}
}

@media (max-width: 1160px) {
	.intro {
		margin-top: 58px;
		.layout-left {
			.content {
				font-size: 20px;
				max-width: 459px;
			}
			.feature {
				font-size: 20px;
			}
		}
	}
}

@media (max-width: 880px) {
	.intro {
		margin-top: 50px;
		display: block;
		.layout-left {
			width: 100%;
			.head {
				display: none;
			}
			.head-sm {
				display: block;
				font-family: Roboto;
				font-size: 40px;
				font-style: normal;
				font-weight: 700;
				line-height: 100%;
				letter-spacing: -0.4px;
				min-width: 240px;
				width: 70%;
				margin-bottom: 24px;
			}
			.content {
				font-size: 18px;
				max-width: 100%;
			}
			.feature {
				font-size: 18px;
			}
		}
	}
}
</style>