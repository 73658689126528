var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "layout-left"
  }, [_c('div', {
    staticClass: "head"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.economic-calendar.get-start")) + " "), _c('br'), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "head-sm"
  }, [_vm._v(" " + _vm._s(_vm.titleBefore) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.titleSm))])]), _vm._t("content", function () {
    return [_c('div', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(_vm.content) + " ")])];
  }), _vm.listFeature.length > 0 ? _vm._l(_vm.listFeature, function (feature) {
    return _c('div', {
      key: feature,
      staticClass: "feature"
    }, [_c('IconList'), _vm._v(" " + _vm._s(feature) + " ")], 1);
  }) : _vm._e(), _vm._t("button")], 2), _c('div', [_vm._t("itemRight")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }